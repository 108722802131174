<script setup lang="ts">
import { useStorage } from '@vueuse/core'

const { t } = useI18n({
	useScope: 'local'
})

const isCookieConfirm = useStorage('is_cookie_confirm', '')
const isShow = ref(false)

const onConfirm = () => {
	isShow.value = false
	isCookieConfirm.value = 'yes'
}

const onClose = () => {
	isShow.value = false
	isCookieConfirm.value = 'no'
}

onMounted(() => {
	setTimeout(() => {
		isShow.value = true
	}, 1000)
})
</script>

<template>
	<ClientOnly>
		<div
			v-if="!isCookieConfirm"
			class="fixed inset-0 z-[150] bg-gray-200/75 transition-opacity opacity-0 duration-300"
			:class="{
				'opacity-100': isShow,
				'pointer-events-none': !isShow,
				'pointer-events-auto': isShow
			}"
		>
			<div
				class="absolute bottom-0 md:bottom-8 left-1/2 -translate-x-1/2 flex flex-col md:flex-row items-center gap-4 bg-white md:rounded-md px-6 py-5 w-full md:w-[700px]"
			>
				<div class="flex-1 text-sm">
					{{ t('text') }}
					<NuxtLink
						to="/uvedomlenie-ob-ispolzovanii-failov-kuki"
						class="text-primary"
					>
						https://totdom.com/uvedomlenie-ob-ispolzovanii-failov-kuki
					</NuxtLink>
				</div>
				<div class="flex flex-row md:flex-col gap-2 md:gap-1">
					<UButton
						size="md"
						:label="t('button.confirm')"
						@click="onConfirm"
					/>
					<UButton
						variant="outline"
						size="md"
						:label="t('button.close')"
						@click="onClose"
					/>
				</div>
			</div>
		</div>
	</ClientOnly>
</template>

<i18n>
{
	"ru": {
		"text": "Наш сайт собирает cookies. Продолжая использовать сайт, вы соглашаетесь с правилами сбора cookies. Подробнее вы можете прочитать в уведомлении об использовании куки",
		"button": {
			"confirm": "Принять",
			"close": "Закрыть"
		}
	}
}
</i18n>